import React from 'react';
import { Chip, chipClasses } from '@mui/material';
import ArrowDownOnSquare from 'public/icons/arrow-down-on-square-2.svg';
import ArrowPath from 'public/icons/arrow-path-2.svg';
import Exit from 'public/icons/exit-2.svg';
import { IMenuListItem } from '../../ui-kit/MenuList/types';
import { EAutomationType } from './types';

export const ComingSoonChip = () => (
  <Chip
    label='Coming soon'
    sx={{
      background: 'rgba(128, 97, 229, 0.16)',
      [`>.${chipClasses.label}`]: {
        color: '#8061E5',
      }
  }}
  />
);

export const AUTOMATIONS_LIST: Array<IMenuListItem & { type: EAutomationType }> = [
  {
    type: EAutomationType.AutoCompounding,
    icon: <ArrowDownOnSquare />,
    label: 'Auto-compounding',
    description: 'Set a trigger for a fees auto-reinvesting',
  },
  {
    type: EAutomationType.AutoRebalancing,
    icon: <ArrowPath />,
    label: 'Auto-rebalancing',
    description: 'Set a trigger for a position auto-rebalancing',
  },
  {
    type: EAutomationType.AutoExit,
    icon: <Exit />,
    label: 'Auto-exit',
    description: 'Set a trigger for a position auto-closure',
    // endIcon: <ComingSoonChip />,
    // disabled: true,
  },
];
