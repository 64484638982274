import { tEthereumAddress } from '@yldr/contract-helpers';
import { useQuery } from '@tanstack/react-query';
import { POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useSharedDependencies } from '../../ui-config/SharedDependenciesProvider';
import { useRootStore } from '../../store/root';

export const useConfiguredAutomationsQuery = ({
  marketId,
  positions = [],
  enabled = true,
}: { marketId?: number, positions?: tEthereumAddress[], enabled?: boolean }) => {
  const { yldrLeverageAutomationsService } = useSharedDependencies();
  const [chainId, isMarketsReady, marketData] = useRootStore((store) => [
    store.currentChainId,
    store.isMarketsReady,
    store.getMarketDataById(marketId),
  ]);

  const leverageAutomationsAddress = marketData.addresses.leverageAutomationsAddress;

  return useQuery({
    queryFn: () =>  {
      return yldrLeverageAutomationsService.getConfiguredAutomations(
        chainId,
        leverageAutomationsAddress,
        positions,
      );
    },
    queryKey: [QueryKeys.YLDR_LEVERAGE_AUTOMATIONS, chainId, marketId, leverageAutomationsAddress, positions],
    enabled: Boolean(marketData.marketId && leverageAutomationsAddress && positions.length) && isMarketsReady && enabled,
    refetchInterval: POLLING_INTERVAL,
  });
}
